<template>
  <div class="login-container">
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'ssoEncrypt',
  data() {
    return {
      userid: '',
      accesstime: '',
      redirect: '',
      empNo: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '40%',
        isFull: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  watch: {
  },
  created() {
    // window.addEventListener('hashchange', this.afterQRScan)
  },
  beforeMount() {
    this.init();
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    init() {

      if (this.$route.query) {
        if (this.$route.query.empno) {
          this.empNo = decodeURIComponent(this.$route.query.empno);
        }
        if (this.$route.query.accesstime) {
          this.accesstime = decodeURIComponent(this.$route.query.accesstime);
        }
        
        if (this.$route.query.redirect) {
          this.redirect = this.$route.query.redirect;
        }
      }
      
      this.loading = true;
      this.$store
        .dispatch('ssoEncrypt', { empNo: this.empNo, accesstime: this.accesstime })
        .then(() => {
          this.loading = false;
          this.$router.push({ path: this.redirect || '/' });
        })
        .catch(error => {
          this.loading = false;
          if (error && error.response.data.returnCode.split('|').length > 1) {

            this.changePassword('비밀번호를 변경해주세요.', this.empNo);
            // this.$router.push('/login');
              // window.getApp.$emit('ALERT', {
              //   title: '안내 ', /* 안내 */
              //   message: '비밀번호 변경일이 도래하였습니다.<br> 다시 로그인 해주시기 바랍니다.', /* 다시 로그인 해주시기 바랍니다. */
              //   type: 'info', // success / info / warning / error
              // });
            // _resolve(false);
          } else {
            this.$store
              .dispatch('LogOut')
              .then(() => {
                this.$router.push({ path: '/ssoFail' });
              })
              .catch(err => {
                this.$router.push({ path: '/ssoFail' });
              });
            this.$comm.movePage(this.$router, '/404');
          }
        });
    },
    changePassword(message, returnUserId) {
      this.popupOptions.title = '비밀번호 변경 안내(EHS접속 전용 비밀번호)'; // 비밀번호 변경
      this.popupOptions.param = {
        aesLoginId: returnUserId,
        message: message
      }
      this.popupOptions.target = () => import(`${'./initChangePwSso.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$router.push('/login');
    },
  },
};
</script>